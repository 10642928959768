import styled from "styled-components";
import {
  Rem,
  BP,
} from "../../../commons/Theme";

export const BeeWrapper = styled.div`
  position: relative;
  padding-top: 2rem;
  @media (${BP.tablet}) {
    padding: ${Rem(150)} 0;
    max-width:90%;
    margin: 0 auto;
  }
`;

export const WrapperImage = styled.div`
  margin-top: -3rem;
  .gatsby-image-wrapper {
    width: 100%;
  }
`;
